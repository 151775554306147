import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../layouts/PageLayout/PageLayout";
import ScrollingNavLayout from "../layouts/ScrollingNavLayout/ScrollingNavLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityResearchPage } from "../model/impact";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query ResearchPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityResearchPage(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 7 })
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "impact" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const ResearchPage = ({
  pageContext,
  data: { menus, page, campaigns },
  location
}: ResearchPageProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <ScrollingNavLayout
        title={title}
        campaigns={campaigns.nodes}
        content={page._rawContent}
      />
    </PageLayout>
  );
};

export default ResearchPage;

interface ResearchPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityResearchPage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
