import React, { ReactNode } from "react";
import BlockContent, {
  CustomEmbeds
} from "../../components/BlockContent/BlockContent";
import BlockContentOutline, {
  hasHeadings
} from "../../components/BlockContentOutline/BlockContentOutline";
import Card from "../../components/Card/Card";
import ContentWithRightAside from "../../components/ContentWithRightAside/ContentWithRightAside";
import Credits from "../../components/Credits/Credits";
import TitleRow from "../../components/TitleRow/TitleRow";
import {
  ContentFields,
  SanityCampaign,
  SanityRawContent
} from "../../model/common";
import { splitByH2 } from "../../utils/blocks";
import OneColumnLayout from "../OneColumnLayout/OneColumnLayout";
import TwoColumnLayout from "../TwoColumnLayout/TwoColumnLayout";

export interface ScrollingNavLayoutProps extends ContentFields {
  campaigns: SanityCampaign[];
  title: string;
  customEmbeds?: CustomEmbeds;
  social?: boolean;
  socialTitle?: string;
  right?: ReactNode;
}

export const ScrollingNavLayout = ({
  campaigns,
  title,
  content,
  customEmbeds,
  social,
  socialTitle,
  licence,
  translationSponsors,
  right
}: ScrollingNavLayoutProps) => {
  const contentCard = (
    <Card type="main">
      <Content customEmbeds={customEmbeds} content={content} right={right} />
      <Credits translationSponsors={translationSponsors} licence={licence} />
    </Card>
  );

  const useSimpleLayout = !hasHeadings(content);
  if (useSimpleLayout) {
    return <OneColumnLayout title={title} main={contentCard} />;
  }

  const aside = (
    <nav>
      <BlockContentOutline subMenuStyle={false} content={content} />
    </nav>
  );
  const top = (
    <TitleRow title={title} social={social} socialTitle={socialTitle} />
  );
  return (
    <TwoColumnLayout
      campaigns={campaigns}
      topRows={top}
      aside={aside}
      main={contentCard}
    />
  );
};

interface ContentProps {
  content: SanityRawContent;
  right?: ReactNode;
  customEmbeds?: CustomEmbeds;
}

const Content = ({ content, right, customEmbeds }: ContentProps) => {
  if (right) {
    const split = splitByH2(content, true);
    const remainder = [].concat(...split.slice(1).map(s => s.content));
    return (
      <ContentWithRightAside
        main={
          <BlockContent
            content={split[0]?.content || []}
            customEmbeds={customEmbeds}
          />
        }
        below={
          <BlockContent
            before={<p />}
            content={remainder}
            cap="2/3"
            customEmbeds={customEmbeds}
          />
        }
        right={right}
      />
    );
  }
  return (
    <BlockContent content={content} cap="3/4" customEmbeds={customEmbeds} />
  );
};

export default ScrollingNavLayout;
