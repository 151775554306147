import classNames from "classnames";
import React, { ReactNode } from "react";
import { Col, Row, Wrapper } from "../../components/Grid";
import TitleRow from "../../components/TitleRow/TitleRow";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import styles from "./OneColumnLayout.module.scss";

export const OneColumnLayout = ({
  title,
  visuallyHiddenTitle,
  main,
  social = true,
  socialTitle,
  widths = { sm: 10, md: 8 },
  className
}: OneColumnLayoutProps) => {
  return (
    <Wrapper className={classNames(styles.container, className)}>
      {visuallyHiddenTitle && (
        <VisuallyHidden>
          <h1>{visuallyHiddenTitle}</h1>
        </VisuallyHidden>
      )}
      <TitleRow
        social={social}
        socialTitle={socialTitle}
        title={title}
        className={styles.titleRow}
        {...widths}
        ariaHiddenTitle={visuallyHiddenTitle ? true : false}
      />
      <Row justify="center">
        <Col {...widths} as="main">
          {main}
        </Col>
      </Row>
    </Wrapper>
  );
};

interface Widths {
  sm: number;
  md: number;
}

export interface OneColumnLayoutProps {
  main: ReactNode;
  title: string;
  visuallyHiddenTitle?: string;
  social?: boolean;
  socialTitle?: string;
  widths?: Widths;
  className?: string;
}

export default OneColumnLayout;
