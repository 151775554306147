import { Row } from "../../components/Grid";
import React, { ReactNode } from "react";
import { SpacedCol } from "../../components/Spacing/Spacing";
import styles from "./ContentWithRightAside.module.scss";

interface ContentWithRightAsideProps {
  main?: ReactNode;
  right?: ReactNode;
  below?: ReactNode;
}

/**
 * Typically used inside a card with block content in main and
 * quick links on the right.
 */
const ContentWithRightAside = ({
  main,
  right,
  below
}: ContentWithRightAsideProps) => {
  return (
    <Row className={styles.root}>
      <SpacedCol>{main}</SpacedCol>
      <SpacedCol xs={12} sm={4} className={styles.right}>
        {right}
      </SpacedCol>
      <SpacedCol xs={12}>{below}</SpacedCol>
    </Row>
  );
};

export default ContentWithRightAside;
